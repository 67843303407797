@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-900;
}

.logo-container {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-image {
  width: 80%;
  min-width: 600px;
  height: auto;
  filter: drop-shadow(0 4px 6px rgba(11, 132, 255, 0.1));
  margin-top: -60px;
}

.logo-divider {
  width: 200px;
  height: 2px;
  background: linear-gradient(
    90deg,
    transparent,
    #0B84FF 50%,
    transparent
  );
  margin-top: -120px;
  margin-bottom: 90px;
}

.card {
  @apply bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 transition-all;
}

.card:hover {
  @apply bg-gray-800/60 border-gray-600/50 transform scale-105;
}

.impact-badge {
  @apply px-2 py-0.5 rounded text-xs font-medium;
}

.impact-low {
  @apply bg-green-500/20 text-green-300;
}

.impact-medium {
  @apply bg-yellow-500/20 text-yellow-300;
}

.impact-high {
  @apply bg-red-500/20 text-red-300;
}

.setup-section {
  @apply opacity-0 transform translate-y-4;
  transition: all 0.3s ease-in-out;
}

.setup-section.active {
  @apply opacity-100 translate-y-0;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
 
    --ring: 217.2 32.6% 17.5%;
  }
}